.modal-container {
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;
	background: rgba(0, 0, 0, 0.7);
}

.task-details {
	display: flex;
	width: 80vw;
	min-width: 900px;
	max-width: 1200px;
	// height: 100%;
	height: 85vh;
	max-height: 650px;
	// border: 2px solid red;
	overflow: hidden;
	// z-index: 99;
	background-color: white;
	border-radius: 0px 0px 5px 5px !important;
}

.left-side {
	display: flex;
	flex-direction: column;
	// width: 17.55vw;
	width: 30%;
	overflow: auto;
	justify-content: space-between;
	// justify-content: space-between;
	padding-bottom: 20px;
	border-right: 0.1rem solid #d8d8d8;
	&.COMPLETE {
		border-top: 0.5rem solid #77c3de;
	}
	&.PAST_DUE {
		border-top: 0.5rem solid #f2be1b;
	}
	&.BLOCKED {
		border-top: 0.5rem solid #f05544;
	}
	&.IN_PROGRESS {
		border-top: 0.5rem solid #18c58d;
	}
}

.right-side {
	display: flex;
	flex-direction: column;
	// width: 29.42vw;
	width: 70%;
	overflow: auto;
	background-color: #fffefb;
}

.comment-field {
	width: 100%;
	border: 1px solid #fed0a5;
	border-radius: 4px;
	margin-inline: 10px;
	// max-height: 130px;
	::placeholder {
		color: #b4b4b4;
		font-size: 14px;
		line-height: 16px;
		font-weight: 400;
		font-family: Calibri;
	}
}
.comment-scroll {
	textarea {
		overflow-y: auto !important;
		-ms-overflow-style: none !important;
		scrollbar-width: none !important;
	}
	textarea::-webkit-scrollbar {
		display: none !important;
	}
}
// .comment-field-attachment {
// 	overflow: hidden;
// 	max-height: 50px;
// }
.paper-icon {
	position: absolute;
	left: 92%;
	top: 0;
}
.emoji-icon {
	position: absolute;
	left: 95%;
	top: 0;
}
.emoji-picker {
	position: absolute;
	right: 50px;
	z-index: 100;
	bottom: 65px;
	box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
	border-radius: 4px;
}
.EmojiPickerReact {
	.epr-preview {
		display: none;
	}
}

.task-modal-container {
	border-radius: 4px;
	width: 100%;
	height: 100%;
	padding: 12px;
	overflow: auto;
}

.drag-over-style {
	border: 1px dashed #fed0a5;
	height: 100%;
	width: 100%;
}
// Fixed Title
.task-modal-container.fixed-title {
	overflow: hidden;
}

.task-modal-container.fixed-title > div:first-child {
	padding-bottom: 10px;
}

.task-bar {
	width: 350px;
	height: 38px;
	border-radius: 24px;
	padding: 9px 18px;
	cursor: grab;
	user-select: none;
	position: fixed;
	top: 55px;
	right: 70px;
	z-index: 1000;
	box-shadow: 0px 8px 24px 0px #959da533;
}

.task-details-container {
	display: flex;
	flex-direction: column;
	width: 80vw;
	min-width: 1000x;
	max-width: 90vw;
	// height: 100%;
	height: 100vh;
	max-height: 90vh;
	// border: 2px solid red;
	overflow: auto;
	// z-index: 99;
	background-color: #eef2f3;
	border-radius: 4px !important;
	justify-content: space-between;
	outline: none;
	border: none;

	.edit-task-details-container {
		padding: 12px 8px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.new-edit-input-field {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 16px;
			.input-field {
				width: 100%;
				height: 22px;
				background-color: #ffffff;
				border-radius: 2px 2px 0px 0px;
				padding: 4px;
				border: none;
			}
			.text-area-field {
				width: 100%;
				min-height: 40px;
				background-color: #ffffff;
				border-radius: 2px 2px 0px 0px;
				padding: 4px;
				border: none;
			}
		}
	}
	.ql-container {
		background-color: white;
	}
	.task-details-header {
		width: 100%;
		// min-height: 100px;
		padding: 12px 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.task-details-body {
		width: 100%;
		height: 100%;
		padding: 12px 8px;
		display: flex;
		justify-content: space-between;
		gap: 10px;
		.left-side-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			text-align: left;
			// overflow: auto;
			height: 100%;
			width: 45%;
			gap: 12px;
			// padding-right: 20px;

			.task-details-notes {
				word-break: break-word;
				max-height: 160px;
				padding: 4px;
				border-radius: 4px;
			}
		}
		.right-side-container {
			display: flex;
			flex-direction: column;
			width: 70%;
			position: relative;
			.task-tabs-container {
				width: 100%;
				height: 290px;
				background-color: #fcfdfd;
				border-radius: 4px;
				padding: 12px;
				overflow: hidden;

				.task-tabs {
					display: flex;
					width: 100%;
					box-sizing: border-box;
					color: #fb7a03;
					gap: 12px;
					justify-content: start;
					align-items: center;
					height: 32px;
				}
				.task-tab {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 115px;
					box-sizing: border-box;
					padding: 10x;
					height: 32px;
					border-radius: 3px;
					cursor: pointer;
				}
				.active-tab {
					background-color: #fed0a5;
				}
				.task-tabs-details-container {
					box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
					padding: 12px;
					border-radius: 4px;
					width: 100%;
					height: calc(100% - 52px);
					overflow: auto;
				}
				// .contact-tab-container {
				//     width: 100%;
				//     height: 363px;
				//     background-color: #fcfdfd;
				//     border-radius: 4px;
				// }
			}
			// .task-resources-container {
			// 	width: 100%;
			// 	height: 279px;
			// 	background-color: #fcfdfd;
			// 	border-radius: 4px;
			// }
			.task-dependencies-container {
				width: 100%;
				height: 100px;
				background-color: #f7f9fa;
				margin-top: 12px;
				// margin-bottom: 12px;
				border-radius: 4px;
				.dependent-task-card {
					width: 300px;
					height: 36px;
					border-radius: 4px;
					padding: 8px 12px;
					align-items: center;
					// display: flex;
					cursor: pointer;
					background-color: #ffffff;
					justify-content: space-between;
					box-shadow: 0px 64px 26px rgba(194, 194, 194, 0.01),
						0px 36px 22px rgba(194, 194, 194, 0.05),
						0px 16px 16px rgba(194, 194, 194, 0.09),
						0px 4px 9px rgba(194, 194, 194, 0.1), 0px 0px 0px rgba(194, 194, 194, 0.1);
				}
				.dependent-current-task-card {
					width: 280px;
					min-height: 36px;
					border-radius: 4px;
					padding: 10px 12px 6px 12px;
					align-items: center;
					display: flex;
					justify-content: center;
					background-color: #fed0a5;
					margin: 0px 18px;
					box-shadow: 0px 64px 26px rgba(194, 194, 194, 0.01),
						0px 36px 22px rgba(194, 194, 194, 0.05),
						0px 16px 16px rgba(194, 194, 194, 0.09),
						0px 4px 9px rgba(194, 194, 194, 0.1), 0px 0px 0px rgba(194, 194, 194, 0.1);
					.current-task-text {
						color: #fb7a03;
					}
				}
				.status {
					min-width: 58px;
					min-height: 20px;
					padding: 2px 4px;
					border-radius: 4px;
				}
				.status-IN_PROGRESS {
					background-color: #18c58d;
					color: white !important;
				}
				.status-NOT_STARTED {
					background-color: #b4b4b4;
					color: white;
				}
				.status-COMPLETED {
					background-color: #cfeaf3;
					color: #77c3de;
				}
				.status-BLOCKED {
					background-color: #f05544;
					color: white;
				}
				.status-PAST_DUE {
					background-color: #fae7ae;
					color: #f2be1b;
				}
			}
			.task-comments-container {
				width: 100%;
				min-height: 168px;
				// margin-top: 12px;
				display: flex;
				flex-direction: column;
				flex: 1;
				background-color: #fcfdfd;
				border-radius: 4px;
			}
		}
	}
	.additional-task-details {
		width: 100%;
		height: 596px;
		padding: 16px 24px;
		.contacts-container {
			width: 100%;
			height: 363px;
			background-color: #fcfdfd;
			border-radius: 4px;
		}
		.additional-details-container {
			width: 100%;
			height: 133px;
			margin-top: 12px;
			background-color: #f7f9fa;
			border-radius: 4px;
		}
	}
	.task-details-footer {
		height: 64px;
		width: 100%;
		background-color: #f7f9fa;
		padding: 16px 24px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.expandable-resources-box {
	width: 100%;
	height: auto;
	margin-bottom: 18px;
	min-height: 30px;
	cursor: pointer;
	padding: 8px 12px;
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 0px 64px 26px rgba(194, 194, 194, 0.01), 0px 36px 22px rgba(194, 194, 194, 0.05),
		0px 16px 16px rgba(194, 194, 194, 0.09), 0px 4px 9px rgba(194, 194, 194, 0.1),
		0px 0px 0px rgba(194, 194, 194, 0.1);
}
.expandable-resources-box_greyed {
	cursor: default;
	background-color: #ededed;
	opacity: 0.6;
}

.loading-overlay {
	position: absolute;
	// top: 0;
	// left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.8);
	z-index: 10;
}

.loading-comments {
	pointer-events: none;
	opacity: 0.6;
}
