.UserAdd-Form {
	width: 100%;
	max-height: 75vh;
	height: fit-content;
	position: relative;
	overflow-y: auto;
}

.VerticalSpacer {
	// border: 1px solid red;
	input {
		font-family: 'Calibre';
	}
	label {
		font-family: 'Calibre';
		font-size: 0.8em;
		color: $labelColor;
		text-transform: uppercase;
		margin-bottom: $spacing16b;
	}
}

.err-text {
	font-size: 0.75rem;
	color: #f44336;
}
