

// Spacing
$spacing10: 1rem;
$spacing15: 1.5rem;
$spacing20: 2rem;
$spacing30: 3rem;
$spacing40: 4rem;
$spacing50: 5rem;

$spacing4 : 0.4rem;
$spacing8 : 0rem;
$spacing16 : 1.6rem;
$spacing24 : 2.4rem;
// $spacing32 : 3.2rem;
$spacing64 : 6.4rem;

// Paul Design$ spacing4: 0.4rem;$ spacing8: 0.8rem;
$spacing16: 1.1rem;
$spacing16b: 0.3rem;
$spacing24: 2rem;
$spacing32: 2rem;
$spacing64: 6.4rem;