.bot-logo-container {
	position: absolute;
	z-index: 100;
	right: 32px;
	bottom: 90px;
	width: 50px;
	height: 50px;
	background-color: white;
	border-radius: 50%;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.bot-logo {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background: linear-gradient(180deg, #fb7a03, #fed0a5);
	position: relative;
	animation: spin 2s linear infinite;
}
.bot-logo::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 70%;
	height: 70%;
	background-color: #f0f0f0;
	border-radius: 50%;
	transform: translate(-50%, -50%);
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.chat-bot-modal {
	position: absolute;
	right: 2px;
	bottom: 15px;
	z-index: 99;
	height: 97vh;
	width: 550px;
	background: #f7f9fa;
	box-shadow: 0px 7px 29px 0px #64646f33;
	border-radius: 16px;
	padding: 18px;

	.chat-bot-conatiner {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: space-between;
		justify-content: center;
		.bot-header {
			display: flex;
			height: 32px;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
		}
		.bot-body {
			flex: 1;
			padding: 8px 0;
			height: 100%;
			overflow: auto;

			.message-container {
				display: flex;
				flex-direction: column;
				gap: 12px;
				padding: 12px;
			}

			.suggestion-question {
				box-shadow: 0px 8px 24px 0px #959da533;
				background-color: 'white';
				padding: 8px 12px;
				border-radius: 50px;
				text-align: left;
				width: fit-content;
				cursor: pointer;

				&:hover {
					background-color: #fb7a03;
					color: white;
				}
			}

			.message-text {
				box-shadow: 0px 8px 24px 0px #959da533;
				background-color: 'white';
				padding: 8px 12px !important;
				border-radius: 16px;
				width: fit-content;
				height: auto;
			}
			.typing-dots {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 30px;
			}

			.typing-dots::before {
				content: ' ';
				display: inline-block;
				width: 8px;
				height: 8px;
				background-color: #fb7a03;
				border-radius: 50%;
				box-shadow: 12px 0 0 #fb7a03, 24px 0 0 #fb7a03;
				animation: typing 1.5s infinite ease-in-out;
			}

			@keyframes typing {
				0% {
					box-shadow: 12px 0 0 rgba(251, 122, 3, 0.3), 24px 0 0 rgba(251, 122, 3, 0.3);
				}
				50% {
					box-shadow: 12px 0 0 #fb7a03, 24px 0 0 rgba(251, 122, 3, 0.3);
				}
				100% {
					box-shadow: 12px 0 0 #fb7a03, 24px 0 0 #fb7a03;
				}
			}
		}
		.bot-footer {
			height: 120px;
			box-shadow: 0px 8px 24px 0px #959da533;
			background: #ffffff;
			border-radius: 16px;
			padding: 12px;
			margin-top: 10px;

			.chat-tab {
				display: flex;
				align-items: center;
				border-radius: 50px;
				border: 1px solid #ededed;
				gap: 4px;
				padding: 0 8px;
			}
			.active-chat-tab {
				background-color: #fb7a03;
				color: #ffffff;
			}

			.text-input {
				color: $black;
				border: none !important;
				margin: 0 !important;
				padding: 0 !important;
				color: $darkText !important;
				font-family: 'CentraLight';
				font-weight: light;
				font-size: 14px !important;
				line-height: 18px;
				text-transform: initial !important;
				padding: 8px 6px 4px 6px !important;
				border-radius: 4px;
				background-color: white;
				resize: none;
			}
		}
	}
}
.ai-icon-container {
	position: absolute;
	z-index: 98;
	right: 40px;
	bottom: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	.ai-icon {
		position: relative;
		width: 36px;
		height: 36px;
		animation: rotate 3s linear infinite;
		// border: 1px solid #fb7a03;
		transition: width 0.3s ease, height 0.3s ease;
		&:hover {
			width: 44px;
			height: 44px;
		}
	}
	.ai-icon::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: rgb(255, 255, 255);
		border-radius: 50%;
		z-index: -1;
	}
	.ai-icon::after {
		content: '';
		position: absolute;
		top: -4px;
		left: -4px;
		right: -4px;
		bottom: -4px;
		background: linear-gradient(180deg, #fb7a03, #fed0a5);
		border-radius: 50%;
		animation: rotateBorder 10s linear infinite;
		z-index: -2;
	}
	.glow {
		position: absolute;
		width: 100%;
		height: 100%;
		background: radial-gradient(
			circle,
			rgba(0, 255, 255, 0.2) 0%,
			rgba(255, 0, 255, 0.1) 50%,
			rgba(0, 0, 0, 0) 70%
		);
		filter: blur(15px);
		animation: glowPulse 10s ease-in-out infinite;
	}
	@keyframes rotate {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	@keyframes rotateBorder {
		0% {
			transform: rotate(0deg) scale(1);
			opacity: 1;
		}
		50% {
			transform: rotate(180deg) scale(1.1);
			opacity: 0.8;
		}
		100% {
			transform: rotate(360deg) scale(1);
			opacity: 1;
		}
	}

	@keyframes glowPulse {
		0% {
			opacity: 0.4;
		}
		50% {
			opacity: 0.8;
		}
		100% {
			opacity: 0.4;
		}
	}
}
